import { Link } from '@remix-run/react'
import { siteLogo } from '#app/routes/_marketing+/logos/logos.js'
import { Button } from './ui/button'
import { UserDropdown } from './UserDropdown'

export default function Footer({
	user,
	searchBar,
}: {
	user: any
	searchBar: any
}) {
	return (
		<footer className="mt-0 bg-neutral-500 text-white dark:bg-neutral-800">
			<div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
				<div className="md:flex md:justify-between">
					<div className="mb-6 md:mb-0">
						<div>
							<a href="https://chadcluff.com/" className="flex items-center">
								<img src={siteLogo} className="me-3 h-40" alt="Chad Cluff" />

								<span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
									ChadCluff.com
								</span>
							</a>
						</div>
					</div>
					<div className="grid grid-cols-2 gap-2 sm:grid-cols-3 sm:gap-2">
						<div>
							<h2 className="mb-6 text-sm font-semibold uppercase text-neutral-100 dark:text-white">
								Resources
							</h2>
							<ul className="font-medium text-neutral-200 dark:text-gray-400">
								<li className="mb-4">
									<a
										href="https://churchofjesuschrist.org/"
										className="hover:underline"
									>
										Church of Jesus Christ
									</a>
								</li>
								<li>
									<a href="https://byucougars.com/" className="hover:underline">
										BYU Athletics
									</a>
								</li>
							</ul>
						</div>
						<div>
							<h2 className="mb-6 text-sm font-semibold uppercase text-neutral-100 dark:text-white">
								Follow Me
							</h2>
							<ul className="font-medium text-neutral-200 dark:text-gray-400">
								<li className="mb-4">
									<a
										href="https://twitter.com/chadcluff"
										className="hover:underline"
									>
										Twitter Celebrity
									</a>
								</li>
								<li>
									<a
										href="https://instagram.com/dchadcluff"
										className="hover:underline"
									>
										Instagram
									</a>
								</li>
							</ul>
						</div>
						<div>
							<h2 className="mb-6 text-sm font-semibold uppercase text-neutral-100 dark:text-white">
								Legal
							</h2>
							<ul className="font-medium text-neutral-200 dark:text-gray-400">
								<li className="mb-4">
									<a href="privacy" className="hover:underline">
										Privacy Policy
									</a>
								</li>
								<li>
									<a href="terms" className="hover:underline">
										Terms &amp; Conditions
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<hr className="my-6 border-gray-200 dark:border-gray-700 sm:mx-auto lg:my-8" />
				<div className="sm:flex sm:items-center sm:justify-between">
					<span className="text-sm text-neutral-200 dark:text-gray-400 sm:text-center">
						{`© ${new Date().getFullYear()} Chad Cluff . All Rights Reserved.`}
					</span>
					<div className="mt-4 flex sm:mt-0 sm:justify-center">
						<a
							href="https://www.facebook.com/chadcluff"
							className="text-neutral-200 hover:text-neutral-100 dark:hover:text-white"
						>
							<svg
								className="h-4 w-4"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 8 19"
							>
								<path
									fillRule="evenodd"
									d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
									clipRule="evenodd"
								/>
							</svg>
							<span className="sr-only">Facebook page</span>
						</a>
						<a
							href="https://discord.gg/dchadcluff"
							className="ms-5 text-neutral-200 hover:text-neutral-100 dark:hover:text-white"
						>
							<svg
								className="h-4 w-4"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 21 16"
							>
								<path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
							</svg>
							<span className="sr-only">Discord community</span>
						</a>
						<a
							href="https://www.twitter.com/chadcluff"
							className="ms-5 text-neutral-200 hover:text-neutral-100 dark:hover:text-white"
						>
							<svg
								className="h-4 w-4"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 20 17"
							>
								<path
									fillRule="evenodd"
									d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
									clipRule="evenodd"
								/>
							</svg>
							<span className="sr-only">Twitter page</span>
						</a>
						<a
							href="https://github.com/dchadcluff"
							className="ms-5 text-neutral-200 hover:text-neutral-100 dark:hover:text-white"
						>
							<svg
								className="h-4 w-4"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fillRule="evenodd"
									d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
									clipRule="evenodd"
								/>
							</svg>
							<span className="sr-only">GitHub account</span>
						</a>
						<a
							href="#"
							className="ms-5 text-neutral-200 hover:text-neutral-100 dark:hover:text-white"
						>
							<svg
								className="h-4 w-4"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path
									fillRule="evenodd"
									d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z"
									clipRule="evenodd"
								/>
							</svg>
							<span className="sr-only">Dribbble account</span>
						</a>
						<a
							href="https://www.strava.com/athletes/chad_cluff"
							className="ms-5 text-neutral-200 hover:text-neutral-100 dark:hover:text-white"
						>
							<svg
								className="h-4 w-4 fill-neutral-200 hover:fill-neutral-100 dark:hover:fill-white"
								version="1.1"
								id="Capa_1"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 487.811 487.81"
							>
								<g>
									<g id="_x33_6_24_">
										<g>
											<path
												d="M150.463,109.521h150.512c3.955,0,7.16-3.206,7.16-7.161c0-3.955-3.205-7.161-7.16-7.161H150.463
				c-3.955,0-7.161,3.206-7.161,7.161C143.302,106.315,146.508,109.521,150.463,109.521z"
											/>
											<path
												d="M15.853,179.537h150.511c3.955,0,7.161-3.206,7.161-7.161s-3.206-7.16-7.161-7.16H15.853
				c-3.955,0-7.161,3.205-7.161,7.16S11.898,179.537,15.853,179.537z"
											/>
											<path
												d="M56.258,253.214c0,3.955,3.206,7.162,7.161,7.162H213.93c3.955,0,7.161-3.207,7.161-7.162s-3.206-7.16-7.161-7.16H63.419
				C59.464,246.054,56.258,249.259,56.258,253.214z"
											/>
											<path
												d="M142.396,336.44H7.161C3.206,336.44,0,339.645,0,343.6s3.206,7.161,7.161,7.161h135.235c3.955,0,7.161-3.206,7.161-7.161
				S146.351,336.44,142.396,336.44z"
											/>
											<path
												d="M385.729,154.418c21.6,0,39.111-17.513,39.111-39.114s-17.512-39.113-39.111-39.113
				c-21.605,0-39.119,17.513-39.119,39.113C346.609,136.905,364.123,154.418,385.729,154.418z"
											/>
											<path
												d="M450.066,143.155c-22.459,31.459-52.533,35.102-84.895,15.89c-2.203-1.306-11.977-6.691-14.141-7.977
				c-52.061-30.906-104.061-18.786-138.934,30.05c-14.819,20.771,19.455,40.459,34.108,19.93
				c18.018-25.232,40.929-32.533,65.986-24.541c-12.83,22.27-24.047,44.405-39.875,75.853
				c-15.832,31.448-50.787,56.562-84.374,36.92c-24.235-14.165-46.09,20.651-21.928,34.772
				c45.854,26.799,99.619,10.343,127.066-24.493c0.952,0.509,1.958,0.968,3.062,1.354c22.422,7.812,51.814,28.61,60.77,35.981
				c8.953,7.371,24.336,44.921,33.471,63.788c11.082,22.893,46.871,6.219,35.748-16.771c-10.355-21.406-27.736-64.129-41.293-74.938
				c-10.875-8.669-31.988-24.803-49.895-33.956c12.115-23.466,24.729-46.679,38.008-69.491
				c42.328,12.969,82.561-2.308,111.215-42.446C498.996,142.312,464.73,122.624,450.066,143.155z"
											/>
										</g>
									</g>
								</g>
							</svg>
						</a>
					</div>
				</div>
				<hr />
				<div className="mt-6">
					<div>{searchBar}</div>
				</div>
				<div className="flex items-center gap-10">
					{user ? (
						<UserDropdown />
					) : (
						<Button asChild variant="outline" size="lg">
							<Link to="/login">Log In</Link>
						</Button>
					)}
				</div>
			</div>
		</footer>
	)
}
