import { Link } from '@remix-run/react'
import { siteLogoTransparent } from '#app/routes/_marketing+/logos/logos.js'

export default function Logo() {
	return (
		<Link to="/" className="group grid leading-snug">
			<div className="flex justify-center">
				<div className="flex gap-2">
					<img
						src={siteLogoTransparent}
						className="inline-flex h-24 w-24"
						alt="Chad Cluff"
					/>
				</div>
				<div className="flex flex-col flex-wrap justify-center">
					<span className="block font-bold transition group-hover:-translate-x-1">
						Chad
					</span>
					<span className="block font-light transition group-hover:translate-x-1">
						Cluff
					</span>
				</div>
			</div>
		</Link>
	)
}
