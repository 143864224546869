import { Link, useLocation } from '@remix-run/react'
import { ThemeSwitch } from '#app/routes/resources+/theme-switch.js'
import { type Theme } from '#app/utils/theme.server.js'
import Logo from './Logo'

export default function Header({ theme }: { theme: Theme | null }) {
	const location = useLocation()
	const isIndex = location.pathname === '/'
	return (
		<header className="container sticky top-0 z-50 m-0 w-full max-w-full bg-neutral-500 py-2 text-white dark:bg-neutral-800">
			<nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
				<div>
					<Logo />
				</div>
				<div className="flex items-center gap-4">
					<Link to="/" className="text-white dark:text-white">
						Home
					</Link>

					<Link to="/#family" className="text-white dark:text-white">
						Family
					</Link>
					<Link to="/#running" className="text-white dark:text-white">
						Running
					</Link>
					<Link to="/#travel" className="text-white dark:text-white">
						Travel
					</Link>
					<Link to="/#developer" className="text-white dark:text-white">
						Developer
					</Link>
				</div>
				<div className="">
					<ThemeSwitch userPreference={theme} />
					<span className="hidden xl:flex 2xl:hidden">xl</span>
					<span className="hidden lg:flex xl:hidden">lg</span>
					<span className="hidden md:flex lg:hidden">md</span>
					<span className="hidden sm:flex md:hidden">sm</span>
					<span className="3xl:hidden hidden 2xl:flex">xxl</span>
				</div>
			</nav>
		</header>
	)
}
